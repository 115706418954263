<template>
    <div class="rounded">
        <div class="bg-white w-full px-1.5" id="bar">
            <p class="sm:container text-gray-dark text-md pt-2">Clienti</p>
        </div>
        <div class="bg-white z-10 text-gray-dark text-xs md:text-sm p-1.5 space-x-3 w-full" id="header">
            <div class="flex flex-col space-y-1.5 w-full sm:container">
                <div class="flex w-full items-center space-x-2">
                    <div class="flex flex-grow bg-gray-light items-center pr-1 space-x-1 rounded">
                        <input class="border-0 bg-gray-light" placeholder="Cerca cliente" type="search"
                               v-model="search">
                        <i class="fa-solid rounded pr-1 fa-magnifying-glass cursor-pointer"></i>
                    </div>
                    <p class="text-base text-gray-dark cursor-pointer mr-2 hover:text-primary"
                       @click="open_filter_slideover"><i
                            class="fa-solid fa-filter"></i></p>
                </div>
                <Transition
                        enter-active-class="duration-300 ease-in-out"
                        enter-from-class="transform-all opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="transform-all opacity-0"
                >
                    <div v-if="filters.sales_agent_id || filters.type || filters.person_type || search"
                         class="flex flex-wrap space-x-2">
                        <TransitionGroup
                                enter-active-class="duration-300 ease-in-out"
                                enter-from-class="transform-all opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="duration-100 ease-in"
                                leave-from-class="opacity-100"
                                leave-to-class="transform-all opacity-0"
                        >
              <span v-if="is_super_agent && agents && filters.sales_agent_id !== null"
                    @click="filters.sales_agent_id = null"
                    class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                      class="fa-solid fa-xmark"></i> Agente: {{ agents.filter(v => v.id === filters.sales_agent_id)[0].company_name }} </span>
                            <span v-if="filters.type !== null" @click="filters.type = null"
                                  class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                                    class="fa-solid fa-xmark"></i> {{ filters.type === 1 ? 'Attivi' : 'Inattivi' }} </span>
                            <span v-if="filters.person_type !== null" @click="filters.person_type = null"
                                  class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                                class="fa-solid fa-xmark"></i> {{ filters.person_type === 1 ? 'Privati' : 'Business' }} </span>
                            <span v-if="search" @click="search = null"
                                  class="bg-gray-light p-1 rounded text-gray-dark space-x-1 cursor-pointer"><i
                                    class="fa-solid fa-xmark"></i> {{ search }} </span>
                        </TransitionGroup>
                    </div>
                </Transition>

            </div>
        </div>

        <div class="flex space-x-1.5">
            <router-link :to="{name: 'customers.create'}"
                         class="btn btn-primary text-xs flex items-center justify-center h-[3.5rem] w-[3.5rem] z-10 rounded-full fixed bottom-[5rem] right-2 md:bottom-md md:right-3 lg:right-[3.5rem] shadow">
                <i class="fas fa-plus"></i></router-link>
        </div>

        <div class="lg:container mt-2 p-1.5">
            <p v-if="!exit && reducedCustomerInactive" class="text-center"><i
                    class="ml-1 fa-solid fa-triangle-exclamation text-[#FFCC00]"></i> Alcuni clienti non hanno
                effettuato acquisti
                da più di un mese.</p>
            <div class="bg-white border border-thin border-gray-light rounded-xl relative">
                <div v-if="loading"
                     class="absolute left-0 top-0 right-0 bottom-0 bg-smoke rounded flex items-center justify-center">
                    <span class="text-white text-md">Caricamento <i
                            class="fas text-base fa-circle-notch animate-spin"></i></span>
                </div>
                <table class="table-auto min-w-full h-full">
                    <tr class="text-gray-dark">
                        <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light rounded-tl  hidden md:table-cell">
                            ID
                        </th>
                        <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Nome</th>
                        <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light">Sconto</th>
                        <th class="font-normal text-left px-1.5 py-1.5 bg-gray-light hidden md:table-cell">Metodo
                            pagamento
                        </th>
                        <th class="font-normal text-center px-1.5 py-1.5 bg-gray-light rounded-tr">Azioni</th>
                    </tr>
                    <TransitionGroup tag="tbody"
                                     enter-active-class="duration-700 ease-in-out"
                                     enter-from-class="transform-all opacity-0"
                                     enter-to-class="opacity-100"
                                     leave-active-class="duration-100 ease-in"
                                     leave-from-class="opacity-100"
                                     leave-to-class="transform-all opacity-0"
                    >
                        <tr class="border-b border-b-thin border-gray-light text-sm bg-white"
                            v-for="customer in customersFiltered" :key="customer.id"
                            :class="customer.commercial_inactive ? 'bg-[#fafad9] border-[#fae8ca]' : 'bg-white border-gray-light'">
                            <td class="p-1.5 hidden md:table-cell">{{ customer.id }}</td>
                            <td class="p-1.5">
                                <div class="flex flex-col">
                <span class="text-xs font-bold text-gray-dark"
                      v-if="is_super_agent">Seguito da {{ customer.sales_agent }}</span>
                                    <span>{{ customer.company_name }}</span>
                                </div>

                            </td>
                            <td class="p-1.5 whitespace-nowrap">{{
                                customer.discount_mode === 'DYNAMIC' ? 'FASCE' : 'FISSO'
                                }}
                                {{ customer.discount }}%
                            </td>
                            <td class="p-1.5 hidden md:table-cell">{{ customer.default_payment_method }}</td>
                            <td class="p-1.5 text-center flex flex-nowrap h-full items-center justify-between text-base">
                                <router-link :to="{name: 'customers.show', params: {id: customer.id}}"
                                             class="text-primary-light hover:text-primary"><i
                                        class="fas fa-arrow-right"></i>
                                </router-link>
                                <i v-if="customer.commercial_inactive"
                                   class="ml-1 fa-solid fa-triangle-exclamation text-[#FFCC00] cursor-pointer"
                                   @click="open_warning_modal(customer)"></i>
                            </td>
                        </tr>
                    </TransitionGroup>
                </table>
            </div>
        </div>

        <modal ref="warning_modal">
            <div class="flex flex-col space-y-1.75 p-1">
                <span class="text-md font-bold text-primary-light">Attenzione</span>
                <span class="text-gray-dark">Il cliente <span class="text-primary">{{
                    selected_customer.company_name
                    }}</span> non ha acquistato da più di un mese.</span>
                <span class="text-gray-dark">Ultimo acquisto fatto il: {{ selected_customer.last_order }}</span>
                <div class="flex w-full justify-end">
                    <button class="btn btn-primary" @click="$refs.warning_modal.close()">OK, Capito</button>
                </div>
            </div>
        </modal>
        <slideover ref="filter" title="Filtri">
            <template v-slot:content>
                <ic v-if="is_super_agent">
                    <label for="agent">Agenti</label>
                    <select v-model="filters.sales_agent_id" id="agent">
                        <option :value="null">Tutti</option>
                        <option v-for="agent in agents" :value="agent.id">{{ agent.company_name }}</option>
                    </select>
                </ic>
                <ic>
                    <label for="type">Attivo</label>
                    <select v-model="filters.type" id="type">
                        <option v-for="type in types" :value="type.value">{{ type.label }}</option>
                    </select>
                </ic>
                <ic>
                    <label for="person_type">Tipo</label>
                    <select v-model="filters.person_type" id="person_type">
                        <option v-for="type in person_types" :value="type.value">{{ type.label }}</option>
                    </select>
                </ic>
            </template>
        </slideover>
        <div class="h-[7rem] w-full xl:hidden"></div>
        <navbar></navbar>
    </div>
</template>

<script>
import navbar from "../../components/navbar.vue";

export default {
    name: "customer-index",
    components: {navbar},

    data: () => ({
        customers: {
            values: []
        },
        search: null,
        loading: false,
        selected_customer: null,
        is_super_agent: false,
        exit: false,
        agents: [],
        types: [
            {value: null, label: 'Tutti'},
            {value: 1, label: 'Solo Attivi'},
            {value: 2, label: 'Solo Inattivi'},
        ],
        person_types: [
            {value: null, label: 'Tutti'},
            {value: 1, label: 'Solo Privati'},
            {value: 2, label: 'Solo Business'},
        ],
        filters: {
            sales_agent_id: null,
            type: null,
            person_type: null,
        }
    }),
    methods: {
        async load() {
            this.loading = true;
            this.customers.values = (await axios.get('/clienti/customers')).data;
            this.loading = false;
        },
        open_warning_modal(customer) {
            this.selected_customer = customer;
            this.$refs.warning_modal.open();
        },
        async open_filter_slideover() {
            this.$refs.filter.open();
            let tmp = localStorage['is_super_agent'] === 'true';
            if (tmp && !this.agents.length) {
                this.$refs.filter.set_loading(true);
                this.agents = (await axios.get('/sales-agent')).data;
                this.$refs.filter.set_loading(false);
            }
        }
    },
    async mounted() {
        let header = document.getElementById('header');
        let sticky = header.offsetTop;
        let bar = document.getElementById('bar');
        window.onscroll = () => {
            if (window.pageYOffset > sticky) {
                header.classList.add('sticky');
                header.classList.add('top-0');
                bar.classList.remove('top-0');
            } else {
                bar.classList.add('top-0');
                header.classList.remove('sticky');
                header.classList.remove('top-0');
            }
        }
        if (typeof localStorage['is_super_agent'] === 'undefined') {
            this.loading = true;
            localStorage['is_super_agent'] = (await axios.get('/super-agent')).data;
            this.loading = false;
        }
        this.is_super_agent = localStorage['is_super_agent'] === 'true';
        await this.load();
    },
    computed: {
        customersFiltered: function () {
            let tmp = this.customers.values.slice(0);
            let tmp2 = this.search ? tmp.filter(v => (v.id + '').includes(this.search.toLowerCase()) || v.company_name.toLowerCase().includes(this.search.toLowerCase()) || v.sales_agent.toLowerCase().includes(this.search.toLowerCase()) || (v.tax_number ? v.tax_number.includes(this.search.toLowerCase()) : false) || (v.fiscal_code ? v.fiscal_code.toLowerCase().includes(this.search.toLowerCase()) : false)) : tmp;
            return tmp2.filter(v => this.filters.sales_agent_id ? v.sales_agent_id === this.filters.sales_agent_id : true).filter(v => this.filters.type ? (this.filters.type === 1 ? !v.commercial_inactive : v.commercial_inactive) : true).filter(v => this.filters.person_type ? (this.filters.person_type === 1 ? v.natural_person : !v.natural_person) : true)
        },
        reducedCustomerInactive: function () {
            return this.customers.values && this.customers.values.length && this.customers.values.reduce((v, p) => v || p.commercial_inactive, false)
        },
    },
    beforeDestroy() {
        window.onscroll = () => null
    }
}
</script>

<style scoped>

</style>
