<template>
  <div class="lg:container flex flex-col space-y-3 px-1.5">
    <div class="flex fixed inset-x-0 top-0 z-10 bg-white text-gray-dark text-xs p-2 space-x-3">
      <p class="text-md text-gray-dark cursor-pointer" @click="$router.go(-1)"><i class="fa-solid fa-arrow-left"></i>
      </p>
      <h1 class="text-md text-gray-dark capitalize">Crea Cliente</h1>
      <!--            <button class="btn btn-primary" @click="create_ticket">Assistenza <i class="fa-solid fa-ticket ml-1.5"></i></button>-->
    </div>
    <div class="h-sm"></div>
    <div class="bg-white rounded p-3 flex flex-col space-y-2 mt-2">
      <div class="flex flex-col space-y-2">
        <div class="flex flex-col">
          <label class="text-gray-dark text-xs" for="nation">Nazione</label>
          <select v-model="customer.country_code" id="nation"
                  class="py-1 border-solid border-gray-light bg-transparent">
            <option :value="country_code" v-for="({country_code, name}) in country_codes">{{
                name
              }}
            </option>
          </select>
        </div>
        <div class="flex justify-between space-x-2">
          <ic :e="errors['customer.tax_number']" class="flex flex-col w-1/2">
            <label class="text-gray-dark text-xs" for="tax_number">Partita IVA</label>
            <input type="text" id="tax_number" v-model="customer.tax_number" placeholder="Partita IVA">
          </ic>
          <ic :e="errors['customer.fiscal_code']" class="flex flex-col w-1/2">
            <label class="text-gray-dark text-xs" for="fiscal_code">Codice fiscale</label>
            <input type="text" id="fiscal_code" v-model="customer.fiscal_code" placeholder="Codice Fiscale">
          </ic>
        </div>
        <div v-if="customer.tax_number" class="flex flex-col space-y-2">
          <span class="text-md text-gray-dark">Risultati Creditsafe</span>
          <div v-if="loading_cs">
            <span><i class="fas fa-circle-notch animate-spin"></i></span>
          </div>
          <div v-else-if="results_cs && results_cs.length" class="flex space-x-2 overflow-x-auto p-1">
            <div v-for="result in results_cs"
                 class="flex flex-col space-y-2 rounded-xl border hover:border-secondary p-2 w-auto"
                 @click="assign_creditsafe(result)">
              <span class="text-gray-dark text-sm">{{ result.name }}</span>
              <span class="text-xs">{{ result.address.simpleValue }}</span>
              <span v-if="result.phoneNumbers" class="text-xs">{{ result.phoneNumbers.join(', ') }}</span>
            </div>
          </div>
          <span v-else>Nessun risultato trovato</span>
        </div>
        <div class="flex flex-col space-y-2" v-if="customer.tax_number">
                    <span v-if="customer.creditsafe_id" class="flex items-center"><i
                        class="far fa-check-circle text-availability-0 pr-2"></i>
                        cliente verificato tramite Creditsafe con id {{ customer.creditsafe_id }}
                        <button class="text-sm" @click="customer.creditsafe_id = null">[rimuovi associazione]</button>
                    </span>
          <span v-else class="flex items-center"><i class="far fa-times-circle text-availability-4 pr-2"></i>
                        cliente business non verificato
                    </span>
        </div>
      </div>
      <div class="flex flex-col mt-2 space-y-2">
        <ic :e="errors['customer.company_name']" class="flex flex-col">
          <label class="text-gray-dark text-xs" for="company_name">Denominazione Cliente</label>
          <input type="text" v-model="customer.company_name" id="company_name">
        </ic>
        <div>
          <div class="flex items-center mb-2">
            <p class="font-bold text-gray-dark mb-0">Sede Legale</p>
          </div>
          <div class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label class="text-gray-dark text-xs" for="country_code">Nazione</label>
              <select v-model="branch.country_code" id="country_code"
                      class="py-1 border-solid border-gray-light bg-transparent">
                <option :value="country_code" v-for="({country_code, name}) in country_codes">{{ name }}
                </option>
              </select>
            </div>
            <ic :e="errors['branch.address']" class="flex flex-col">
              <label class="text-gray-dark text-xs" for="address">Indirizzo</label>
              <input type="text" v-model="branch.address" id="address">
            </ic>
            <ic :e="errors['branch.city']" class="flex flex-col">
              <label class="text-gray-dark text-xs" for="city">Città</label>
              <input type="text" v-model="branch.city" id="city">
            </ic>
            <ic :e="errors['branch.zip_code']" class="flex flex-col">
              <label class="text-gray-dark text-xs" for="zip_code">CAP</label>
              <input type="text" v-model="branch.zip_code" id="zip_code">
            </ic>
            <div class="flex items-center">
              <input type="checkbox" id="branch_shipment" v-model="branch.shipping">
              <label for="branch_shipment" class="ml-1 mb-0">Sede merce</label>
            </div>
          </div>
        </div>
        <div>
          <div class="flex items-center mt-2 mb-2 ">
            <input type="checkbox" id="create_user" v-model="create_user">
            <label for="create_user" class="font-bold ml-2 mb-0">Crea Utente</label>
          </div>
          <div class="flex flex-col space-y-2" v-if="create_user">
            <ic :e="errors['user.email']" class="flex flex-col">
              <label class="text-gray-dark text-xs" for="email">Email</label>
              <input type="text" v-model="user.email" id="email">
            </ic>
            <ic :e="errors['user.phone']" class="flex flex-col">
              <label class="text-gray-dark text-xs" for="city">Telefono</label>
              <input type="text" v-model="user.phone" id="phone">
            </ic>
            <ic :e="errors['user.name']" class="flex flex-col">
              <label class="text-gray-dark text-xs" for="name">Nome e Cognome</label>
              <input type="text" v-model="user.name" id="name">
            </ic>
            <ic class="flex items-center">
              <input type="checkbox" id="login_allowed" v-model="user.login_allowed">
              <label for="login_allowed" class="font-bold ml-2 mb-0">Abilita accesso al sito</label>
            </ic>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mb-2">
      <button class="btn btn-primary self-end"
              :class="{'disabled': loading }"
              @click="create()">
        Crea <span v-if="loading"><i class="fas fa-circle-notch animate-spin"></i></span>
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
export default {
  name: "customer-signup",
  data: () => ({
    customer: {
      country_code: 'IT',
      tax_number: null,
      fiscal_code: null,
      company_name: null,
      creditsafe_id: null
    },
    create_user: false,
    branch: {
      country_code: 'IT',
      city: null,
      zip_code: null,
      shipping: false
    },
    user: {
      email: null,
      phone: null,
      name: null,
      login_allowed: false
    },
    results_cs: null,
    loading_cs: false,
    loading: false,
    country_codes: null,
    errors: []
  }),
  methods: {
    debounce: _.debounce(function () {
      this.search_creditsafe()
    }, 250),
    assign_creditsafe(result) {
      this.branch.country_code = result.country;
      this.customer.creditsafe_id = result.id;
      this.customer.company_name = result.name;
      this.customer.fiscal_code = result.vatNo[0] ?? null;
      this.customer.tax_number = result.vatNo[1] ?? null;
      this.branch.address = result.address.street + ' ' + result.address.houseNo;
      this.branch.city = result.address.city;
      this.branch.zip_code = result.address.postCode;
    },
    async search_creditsafe() {
      if (this.customer.tax_number && this.customer.country_code)
        this.results_cs = (await axios.get('/creditsafe/search', {
          params: {
            vatNo: this.customer.tax_number,
            countries: this.customer.country_code
          }
        })).data.companies ?? [];
      else this.customer.creditsafe_id = null;
      this.loading_cs = false;
    },
    async create() {
      let loading = this.$loading.show();
      this.loading = true;
      try {
        let customer_id = (await axios.post('/clienti/customers', {
          customer: this.customer,
          branch: this.branch,
          user: this.user,
          create_user: this.create_user
        })).data.id;
        this.go(customer_id);
      } catch (e) {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.errors = e.response.data.errors;
        this.$toast.error("Alcuni campi non sono validi");
      }
      this.loading = false;
      loading.hide();
    },
    go(customer_id) {
      this.$router.replace({name: 'customers.index'});
      this.$router.push({name: 'customers.show', params: {id: customer_id}});
    }
  },
  async mounted() {
    let loading = this.$loading.show();
    let baseURL = null;
    //if (import.meta.env.VITE_ENVIRONMENT || 'development' === 'development') baseURL = 'http://localhost:8000';
    baseURL = 'https://led-italia.it';
    this.country_codes = (await axios({
      url: '/services/country_codes',
      baseURL: baseURL,
      method: 'GET',
    })).data ?? [];
    loading.hide()
  },
  watch: {
    'customer.tax_number': function () {
      this.loading_cs = true;
      this.debounce()
    }
  }
}
</script>

<style scoped>

</style>
