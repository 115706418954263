<template>
    <div class="lg:container lg:p-2 pt-2">
        <div class="flex justify-between fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
            <div class="flex w-full space-x-1.5 w-10/12 sm:w-11/12 lg:w-auto">
                <p class="text-md text-gray-dark capitalize truncate">Menu</p>
            </div>
        </div>
        <div class="h-5"></div>
        <div class="w-full">
            <router-link :to="{name: 'dashboard'}"
                         class="px-1.5 md:px-2 pt-2 pb-2 border-b-thin border-gray w-full flex justify-between">
                <span class="text-base font-bold text-gray-dark">Dashboard</span>
                <span class="text-primary-light text-base">
          <i class="fas fa-arrow-right"></i>
        </span>
            </router-link>
            <router-link :to="{name: 'customers.index'}"
                         class="px-1.5 md:px-2 py-2 border-b-thin border-gray w-full flex justify-between">
                <span class="text-base font-bold text-gray-dark">Clienti</span>
                <span class="text-primary-light text-base">
          <i class="fas fa-arrow-right"></i>
        </span>
            </router-link>
            <router-link :to="{name: 'orders.index'}"
                         class="px-1.5 md:px-2 py-2 border-b-thin border-gray w-full flex justify-between">
                <span class="text-base font-bold text-gray-dark">Ordini</span>
                <span class="text-primary-light text-base">
          <i class="fas fa-arrow-right"></i>
        </span>
            </router-link>
            <router-link :to="{name: 'products.index'}"
                         class="px-1.5 md:px-2 py-2 border-b-thin border-gray w-full flex justify-between">
                <span class="text-base font-bold text-gray-dark text-base">Prodotti</span>
                <span class="text-primary-light text-base">
          <i class="fas fa-arrow-right"></i>
        </span>
            </router-link>
            <router-link :to="{name: 'tickets.index'}"
                         class="px-1.5 md:px-2 py-2 border-b-thin border-gray w-full flex justify-between">
                <span class="text-base font-bold text-gray-dark">Assistenza</span>
                <span class="text-primary-light text-base">
          <i class="fas fa-arrow-right"></i>
        </span>
            </router-link>
            <router-link v-if="is_super_agent" :to="{name: 'agents.index'}"
                         class="px-1.5 md:px-2 py-2 border-b-thin border-gray w-full flex justify-between">
                <span class="text-base font-bold text-gray-dark">Agenti</span>
                <span class="text-primary-light text-base">
          <i class="fas fa-arrow-right"></i>
        </span>
            </router-link>
            <router-link v-if="is_metadata_appointee" :to="{name: 'metadata.index'}"
                         class="px-1.5 md:px-2 py-2 border-b-thin border-gray w-full flex justify-between">
                <span class="text-base font-bold text-gray-dark">Metadati Prodotti</span>
                <span class="text-primary-light text-base"><i class="fas fa-arrow-right"></i></span>
            </router-link>
            <button
                    class="px-1.5 md:px-2 py-2 border-b-thin border-gray w-full flex justify-between"
                    @click="logout">
                <span class="text-base font-bold text-secondary">Logout</span>
                <span class="text-secondary text-base">
          <i class="fa-solid fa-right-from-bracket"></i>
        </span>
            </button>
            <p class="text-center mt-2 text-gray-dark text-base">v0.5.29 - dev</p>
            <div class="flex justify-center mt-4 h-5 space-x-2 px-1.5 md:px-2">
                <img src="https://assets.next.led-italia.it/assets/assets/logos/vtac-italia.png" alt="" class="h-5">
                <img src="/src/assets/led-italia-extended.png" alt="" class="h-5">
            </div>
            <p class="mt-2 px-1.5 md:px-2 text-center">© LED Italia S.r.l - All rights reserved</p>
        </div>
        <div class="h-5"></div>
        <navbar></navbar>
    </div>
</template>

<script>
import Navbar from "../../components/navbar.vue";

export default {
    name: "menu-index",
    components: {Navbar},
    data: () => ({
        is_super_agent: false,
        is_metadata_appointee: false
    }),
    methods: {
        logout() {
            localStorage.clear();
            location.reload();
        }
    },
    async mounted() {
        if (typeof localStorage['is_super_agent'] === 'undefined') {
            localStorage['is_super_agent'] = (await axios.get('/super-agent')).data;
        }
        this.is_super_agent = localStorage['is_super_agent'] === 'true';
        if (typeof localStorage['is_metadata_appointee'] === 'undefined') {
            localStorage['is_metadata_appointee'] = (await axios.get('/metadata-appointee')).data;
        }
        this.is_metadata_appointee = localStorage['is_metadata_appointee'] === 'true';
    }
}
</script>

<style scoped>

</style>
