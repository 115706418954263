import {createApp} from 'vue'
import router from './router'
import axios from "axios";
import _ from "lodash";
import {DateTime} from "luxon";
import {LoadingPlugin} from "vue-loading-overlay";
import VueToast from "vue-toast-notification";
import vSelect from 'vue-select';
import mitt from 'mitt'
import navigation from "./components/navigation.vue";
import Create_ticket from "./components/create_ticket.vue";
import './index.css'


import apiSelect from "./components/api-select.vue";
import slideOver from "./components/slideover.vue"
import Enum from "./components/enum.vue";
import btn from "./components/btn.vue";
import ic from "./components/ic.vue"
import modal from "./components/modal.vue";
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";



window.luxon = DateTime;
window.axios = axios;
window._ = _;

const app = createApp(navigation)
    .use(router)
    .use(LoadingPlugin, {
        color: '#447eef',
        backgroundColor: '#2b2e36',
        opacity: 0.7,
        width: 64,
        height: 64
    })
    .use(VueToast, {
        position: 'top-right',
        duration: 3000
    })
    .component('v-select', vSelect)
    .component('api-select', apiSelect)
    .component('slideover', slideOver)
    .component('enum', Enum)
    .component('btn', btn)
    .component('ic', ic)
    .component('create_ticket', Create_ticket)
    .component('modal', modal);

app.config.globalProperties.$components = {
    slideover: {
        background: 'bg-white',
        loading_text_color: '',
        divider_color: ''
    }
};
const emitter = mitt();
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties._ = _;
app.config.globalProperties.window = window;
app.config.globalProperties.$DateTime = window.DateTime;
app.config.globalProperties.$luxon = (value, hours = false) => (hours ? DateTime.fromISO(value).toFormat('f') : DateTime.fromISO(value).toFormat('dd/MM/yyyy'));


/*Sentry.init({
    app,
    dsn: "https://2b511ee75db14e1aa92a2d6225a28719@o1121113.ingest.sentry.io/4504322538209280",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["http://localhost", "https://agenti.led-italia.it", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});*/
app.mount('#app');

let maps_key = document.head.querySelector('meta[name="maps_key"]');
if (maps_key) window.maps_key = maps_key.content;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
//if(import.meta.env.VITE_ENVIRONMENT || 'development' === 'development') window.axios.defaults.baseURL = 'http://localhost:8000/piattaforma-agenti';
window.axios.defaults.baseURL = 'https://led-italia.it/piattaforma-agenti';
if(localStorage['key-api']) window.axios.defaults.headers.common['Authorization'] = localStorage['key-api'];
if(localStorage['key-user']) window.axios.defaults.headers.common['X-User-Authorization'] = localStorage['key-user'];
window.axios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) app.$toast.error(error.response.data.message);
    if (error.response.status === 422 && error.response.data.message && !error.response.data.errors) app.$toast.error(error.response.data.message);
    return Promise.reject(error);
});

